import { useTranslation } from 'react-i18next'

import './card.css'





const Card = () => {
    const { t } = useTranslation();
    const cardInfo = [
        {
            title: t("cardTitle"),
            cardFront: 'img/card/cardFront.png',
            cardOffer: t("cardOffer"),
            cardPoints: t("cardPoints"),
            cardLink: 'https://t.me/CSCNavigation_Bot',
            cardLinkImg: 'img/card/telegramWhite.png'
        }
    ]

    return (
        <div className="offerCard">
            <div className="titleCard">
                <h2>{cardInfo[0].title}</h2>
            </div>
            <div className="descriptionCard">
                <div className="imgCard">
                    <img src={cardInfo[0].cardFront} alt="" />
                </div>
                <div className="offerText">
                    {cardInfo[0].cardOffer}
                </div>
            </div>
            <div className="offerPoints">
                <img src={cardInfo[0].cardPoints} alt="" />
            </div>
            <div className="telegramCard">
                <a href={cardInfo[0].cardLink}>
                    <p>{t("cardButton")}</p>
                    <img src={cardInfo[0].cardLinkImg} alt="" />
                </a>
            </div>
        </div>

    )
}

export default Card;