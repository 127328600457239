import './login.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'




async function login(e) {
    e.preventDefault();

    const loginForm = new FormData();
    loginForm.append('username', document.querySelector(".loginUsername").value);
    loginForm.append('password', document.querySelector(".loginPassword").value);


    await axios.post(process.env.REACT_APP_API_URL + '/auth/login/', loginForm)
        .then(function (response) {
            document.cookie = `csctoken=${response.data.token}`;
            localStorage.setItem("csclogin", document.querySelector(".loginUsername").value);
            window.location.reload();
        })
        .catch(function (error) {
            const lge = document.querySelector(".errorMessage");
            lge.classList.remove("dn");
        });
}

const Login = () => {


    const { t } = useTranslation();


    return (
        <div className="loginFormContainer" id="login">
            <h2>
                {t("lAuth")}
            </h2>
            <form className="loginForm" action='#' encType='multipart/form-data' onSubmit={login}>
                <div className="loginInputs">
                    <input required placeholder="LOGIN" type="text" name="username" className='loginUsername' />
                    <input required autoComplete="current-password" placeholder="PASSWORD" type="password" name="password" className='loginPassword' />
                </div>
                <button className="loginBtn" >
                {t("lLogin")}
                </button>

            </form>
            <div className="errorMessage dn">{t("Errormessage")}</div>
            <div className="optionsLogin">
                <Link to={"/registration"}>{t("Toregistration")}</Link>


            </div>
            {/* РЕГИСТРАЦИЯ ЧЕРЕЗ ТЕЛЕГУ
            <a href="https://telegram.com" className="telegramAuth">
                    <img src="img/auth/telegramWhite.png" alt="" />
                </a> */}
        </div>
    )
}

export default Login;
