import "./header.css";
import {Link, Outlet} from 'react-router-dom'
import {useTranslation} from 'react-i18next'



const Header = () => {
    const {t} = useTranslation();
    
    const menu = [
        {title: t("agency"), rel: "/agency" },
        {title: t("consumables"), rel: "/consumables"},
        {title: t("spend"), rel: "/spend"},
        {title: t("card"), rel: "/card"},
        {title: t("profile"), rel:"/profile"}
    ]
    
    function showBurger () {
        const menu = document.querySelector(".mainMenu")
        menu.classList.add('activeBurger')

    }

    function choiseBurger () {
        const menu = document.querySelector(".mainMenu")
        menu.classList.remove('activeBurger')

    }
    

    const menuItems = menu.map (item => {
        return (
            <li>    
                    <Link key={item.rel} onClick={choiseBurger} to={item.rel}>{item.title}</Link>

            </li>
        )
    })

    
    return (
        <header>
            <div className="burgerCont">
                <div className="CSlogo">
                    <img src="img/logotype2.png" alt=""/> 
                </div>
                <div className="burgerBtnCont">
                    <div className="burgerOn">
                        <img onClick={showBurger} src="img/burger.png" alt=""/> 
                    </div>
                </div>
            </div>
            <nav className="mainMenu">
                <ul>
                    {menuItems}
                </ul>
            </nav>
            <Outlet />
        </header>
        
    )
}

export default Header;