import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const scrollToTop = () => {
        const a = document.querySelector('#root');
        console.log(a);
        a.scrollTo({ top: 0 });
    };

    return (
        <div className="footer" >
            <div className="footerLinks">
                <Link to={"/termsandconditions"} style={{ whiteSpace: "nowrap" }} onClick={scrollToTop}>terms and conditions</Link>
                <a href="">contact us</a>
                <Link to={"/privacypolicy"} style={{ whiteSpace: "nowrap" }} onClick={scrollToTop}>privacy policy</Link>
            </div>
            <div className="footerSocialCont">
                <div className="footerSocial">
                    <a href="https://t.me/CSCNavigation_Bot"><img src="img/footerTelegram.png" alt="" /></a>
                    <a href="https://www.instagram.com/clickstormcorp/"><img src="img/footerInstagram.png" alt="" /></a>
                </div>
            </div>
            <a className="mailTo" href="mailto:clickstormcorp@gmail.com">clickstormcorp@gmail.com</a>
            <div className="copyright">&copy; 2024 Clickstormcorp All Right Reserved</div>
        </div>
    );
};

export default Footer;
