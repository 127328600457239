import './noauth.css'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'







const Noauth = () => {

    const { t } = useTranslation();
    return (

        <div className="notAuthSpend">
            <div className="notAuthMessage">
                <p>
                    {t("auM1")} <br />  {t("auM2")}
                </p>
                <Link to={"/login"}> {t("auM3")}</Link>
            </div>
        </div>
    )
}


export default Noauth;
