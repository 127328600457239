import "./agency.css"
import SourceNav from "./source-nav/source-nav"
import SourceList from "./sources-list/sources-list"
import { useTranslation } from 'react-i18next'




const Agency = () => {

    const { t } = useTranslation();

    const sources = [
        {
            title: t("agfacebook"),
            navImg: 'img/agency/navFacebook.png',
            logo: 'img/agency/facebook.png',
            bottom: 'img/agency/facebookBottom.png',
            description: [
                t("agFacebookD11"),
                t("agFacebookD12"),
                t("agFacebookD13"),
                t("agFacebookD14"),
                t("agFacebookD15"),
                t("agFacebookD16"),
                t("agFacebookD17")

            ],
            description2: [
                t("agFacebookD51"),
                t("agFacebookD52"),
                t("agFacebookD53"),
                t("agFacebookD54"),
                t("agFacebookD55"),
                t("agFacebookD56")
            ]
        },
        {
            title: t("agGoogle"),
            navImg: 'img/agency/navGoogle.png',
            logo: 'img/agency/google.png',
            bottom: 'img/agency/googleBottom.png',
            description: [
                t("agGoogleD11"),
                t("agGoogleD12"),
                t("agGoogleD13"),
                t("agGoogleD14"),
                t("agGoogleD15"),
                t("agGoogleD16"),
                t("agGoogleD17"),
                t("agGoogleD18"),
                t("agGoogleD19")
            ]
        },
        {
            title: t("agBigo"),
            navImg: 'img/agency/navBigo.png',
            logo: 'img/agency/bigo.png',
            bottom: 'img/agency/bigoBottom.png',
            description: [
                t("agBigoD11"),
                t("agBigoD12"),
                t("agBigoD13"),
                t("agBigoD14"),
                t("agBigoD15"),
                t("agBigoD16"),
            ]
        },
        {
            title: t("agBing"),
            navImg: 'img/agency/navBing.png',
            logo: 'img/agency/bing.png',
            bottom: 'img/agency/bingBottom.png',
            description: [
                t("agBingD11"),
                t("agBingD12"),
                t("agBingD13"),
                t("agBingD14"),
                t("agBingD15"),
                t("agBingD16")
            ]
        },
        {
            title: t("agTaboola"),
            navImg: 'img/agency/navTaboola.png',
            logo: 'img/agency/taboola.png',
            bottom: 'img/agency/taboolaBottom.png',
            description: [
                t("agTaboolaD11"),
                t("agTaboolaD12"),
                t("agTaboolaD13"),
                t("agTaboolaD14"),
                t("agTaboolaD15"),
                t("agTaboolaD16")
            ]
        },
        // {
        //     title: 'yandex',
        //     navImg: 'img/agency/navYandex.png',
        //     logo: 'img/agency/yandex.png',
        //     bottom: 'img/agency/yandexBottom.png',
        //     description: [
        //         'ПЛАТА ЗА КАБИНЕТ: 50$',
        //         'ПРОЦЕНТ ОТ ПОПОЛНЕНИЯ: 12%',
        //         'МИНИМАЛЬНОЕ ПОПОЛНЕНИЕ: 300$',
        //         'ПРЕМОДЕРАЦИЯ: ЛИНК НА ОФФЕР',
        //         'СРОК ВЫДАЧИ: ОТ 2 ДО 4 ДНЕЙ', 'ВЕРТИКАЛИ: WH/GH'
        //     ]
        // },
        {
            title: t("agTiktok"),
            navImg: 'img/agency/navTiktok.png',
            logo: 'img/agency/tiktok.png',
            bottom: 'img/agency/tiktokBottom.png',
            description: [
                t("agTiktokD11"),
                t("agTiktokD12"),
                t("agTiktokD13"),
                t("agTiktokD14"),
                t("agTiktokD15"),
                t("agTiktokD16")
            ]
        }
    ]
    return (
        <div className="agency" id={'agency'}>
            {/* <div className="adsBlock">
                Блок под рекламу
            </div> */}
            <div className="agencyOffer">
                <img src="img/agency/offerImg.png" alt="" />
                <div className="agencyOfferText">
                    <h1 style={{ whiteSpace: "nowrap" }}>
                        {/* {t("agName")} */}
                        <img src="img/agency/title.png" alt="" />
                    </h1>
                    <img src="img/agency/agLine.png" alt="" />
                    <p>{t("agOffer")}</p>
                </div>
            </div>
            <SourceNav data={sources} />
            <SourceList data={sources} />
        </div>

    )
}

export default Agency;