import React, { Component,useState, useEffect } from 'react';
import './spends.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next'




const doomie = {

    total_spend: "0",
    remaining_deposits: "0",
    telegram: "no_data",
    deposits: [{
        date: '-',
        value: '-',
        commission: '-',
        toCash: '-',
        hash: '-'
    }],
    spends: [{
        acc: '-',
        cab: '-',
        totalSpend: '-',
        monthSpend: [{
        //     date: "no_data",
        //     value: "-"
        // },
        // {
        //     date: "no_data",
        //     value: "-"
        // },{
        //     date: "no_data",
        //     value: "-"
        // 
    }]
    }]
};

class Spend extends Component {
    constructor(props) {
        
        super(props);

        this.state = {
            any: doomie,
        };

        this.spends = this.spends.bind(this);
    }

    

    async componentDidMount() {
        await this.spends();
        
    }

    async spends() {
        try {
            const response = await axios(process.env.REACT_APP_API_URL + '/table/', {
                headers: { "Authorization": `Token ${this.getCookie("csctoken")}` }
            });
            this.setState({ any: response.data});
            const loader = document.querySelector(".loader");
            loader.classList.add('dn');
            const cont = document.querySelector(".spendCont");
            cont.classList.remove('dn');
        } catch (error) {
            this.setState({ any: doomie });
            const cont = document.querySelector(".spendCont");
            cont.classList.remove('dn');
            const loader = document.querySelector(".loader");
            loader.classList.add('dn');
        }
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : "";
    }

    spend() {
        const spend = document.querySelector(".spendSpendData");
        const fill = document.querySelector(".spendFillsData");
        spend.classList.remove('dn');
        fill.classList.add('dn');
    }

    fill() {
        const spend = document.querySelector(".spendSpendData");
        const fill = document.querySelector(".spendFillsData");
        fill.classList.remove('dn');
        spend.classList.add('dn');
    }

    





    render() {
        const { any } = this.state;
        // const { any } = doomie;

        const accCabsTable = any.spends.map(item => (
            <tr key={item.acc + item.cab}>
                <td style={{ whiteSpace: "nowrap" }}>
                    {item.acc}
                </td>
                <td>
                    {item.cab}
                </td>
            </tr>
        ));

        const dateTitleTable = any.spends[0].monthSpend.map(item => (
            <th key={item.date}>
                {item.date}
            </th>
        ));

        const fillsList = any.deposits.map(item => (
            <tr key={item.date + item.value + item.commission + item.toCash + item.hash}>
                <td>
                    {item.date}
                </td>
                <td>
                    {item.value}
                </td>
                <td>
                    {item.commission}
                </td>
                <td>
                    {item.toCash}
                </td>
                <td className="hashTable">
                    {item.hash}
                </td>
            </tr>
        ));

        const dateValuesTableRow = any.spends.map(item => (
            <tr key={item.acc + item.cab + item.totalSpend}>
                {item.monthSpend.map((value, index) => (
                    <td key={index}>
                        {value.value}
                    </td>
                ))}
            </tr>
        ));
        
        
        

        return (
            
            <div className="contSpend">
                
                <div className="contSpend">

                    <div className="loader">
                        <img src="img/giphy.gif" alt="" />
                    </div>
                    <div className="spendCont dn">
                        <div className="spendNavCont">
                            <div className="spendNav">
                                <p className="spendNavSpend" onClick={this.spend}>
                                    ТРАТЫ
                                </p>
                                <p className="spendNavFills" onClick={this.fill}>
                                    ПОПОЛНЕНИЯ
                                </p>
                            </div>
                        </div>
                        <div className="spendGroup">
                            <div>
                                {any.telegram}
                            </div>

                        </div>
                        <div className="spendSpendData">
                            <div className="spendSpendBalanceTitle">
                                Спенд за текущий месяц
                            </div>
                            <div className="spendSpendBalanceValue">
                                <div>
                                    {any.total_spend}
                                </div>
                            </div>

                            


                            <div className="spendSpendTable">
                                <table className="spendAccsCabsTable">
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap" }}>
                                            Название аккаунта
                                        </th>
                                        <th style={{ whiteSpace: "nowrap" }}>
                                            ID кабинета
                                        </th>
                                    </tr>
                                    {accCabsTable}
                                </table>


                                <div className="spendMonthTableCont">
                                    <div className="horizontalScrollbar">
                                        <table className="spendMonthTable" title="Shift + колёсико мыши">
                                            <tr>
                                                {dateTitleTable}
                                            </tr>
                                            {dateValuesTableRow}
                                        </table>
                                    </div>
                                </div>

                                <table className='totalTable'>
                                    <th>
                                        ТОТАЛ
                                    </th>
                                    {any.spends.map((item) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {item.totalSpend}

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </table>

                            </div>

                        </div>
                        <div className="spendFillsData dn">
                            <div className="spendFillsBalanceTitle">
                                Доступный баланс
                            </div>
                            <div className="spendFillsBalanceValue">
                                <div>
                                    {any.remaining_deposits}
                                </div>
                            </div>
                            <div className="spendFillsTable">
                                <table>
                                    <tr>
                                        <th>
                                            Дата
                                        </th>
                                        <th>
                                            Сумма
                                        </th>
                                        <th>
                                            Комиссия
                                        </th>
                                        <th style={{ whiteSpace: "nowrap" }}>
                                            К зачеслению
                                        </th>
                                        <th>
                                            Хеш
                                        </th>
                                    </tr>
                                    {fillsList}
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Spend;
