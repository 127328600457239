import { useTranslation } from 'react-i18next'


import './sources-list.css'

const Sources = ({ data }) => {

    const { t } = useTranslation();



    function fb1a() {
        const one = document.querySelector(".fb1")
        const five = document.querySelector(".fb5")
        one.classList.add('activeAgency')
        five.classList.remove('activeAgency')

        const fbd1 = document.querySelector(".fbd1")
        const fbd5 = document.querySelector(".fbd5")
        fbd1.classList.remove('dn')
        fbd5.classList.add('dn')
    }

    function fb5a() {
        const one = document.querySelector(".fb1")
        const five = document.querySelector(".fb5")
        five.classList.add('activeAgency')
        one.classList.remove('activeAgency')

        const fbd1 = document.querySelector(".fbd1")
        const fbd5 = document.querySelector(".fbd5")
        fbd1.classList.add('dn')
        fbd5.classList.remove('dn')

    }
    function descrip(d) {
        return (
            <ul className='itemDescrip'>
                {d.description.map((value) => {
                    return <li>{value}</li>
                })}
            </ul>
        )
    }

    function fbButton(item) {
        return (
            <div className="fbnav">
                <h3>Формат работы</h3>
                <div className="fbtoggler">
                    <p className="fb1 activeAgency" onClick={fb1a}>
                        провайдинг
                    </p>
                    <p className='fbdc'>|</p>
                    <p className="fb5" onClick={fb5a}>
                        кредитная линия
                    </p>
                </div>
                <ul className='itemDescrip'>
                    <div className='fbd1'>
                        <li>{t("agFacebookD11")}</li>
                        <li>{t("agFacebookD12")}</li>
                        <li>{t("agFacebookD13")}</li>
                        <li>{t("agFacebookD14")}</li>
                        <li>{t("agFacebookD15")}</li>
                        <li>{t("agFacebookD16")}</li>
                        <li>{t("agFacebookD17")}</li>
                    </div>
                    <div className='fbd5 dn'>
                        <li>{t("agFacebookD51")}</li>
                        <li>{t("agFacebookD52")}</li>
                        <li>{t("agFacebookD53")}</li>
                        <li>{t("agFacebookD54")}</li>
                        <li>{t("agFacebookD55")}</li>
                        <li>{t("agFacebookD56")}</li>
                        <li>{t("agFacebookD57")}</li>

                    </div>
                </ul>
            </div>


        )
    }





    const sourceList = data.map(item => (
        <div className="sourceListItem" id={item.title}>

            <div className="itemText">
                <h2>
                    {item.title}
                </h2>

                <img className="bottom" src={item.bottom} alt="" />

                {item.title === 'facebook' ? fbButton(item) : descrip(item)}
                <a href="https://t.me/CSCNavigation_Bot">
                    <img className="atb" src="img/agency/telegramBlack.png" alt="" />
                    <img className="atw" src="img/auth/telegramWhite.png" alt="" />
                </a>
            </div>

            <div className="itemLogo">
                <img src={item.logo} alt="" />
            </div>
        </div>

    ))


    return (
        <main className="sourceList">
            {sourceList}
        </main>

    )
}

export default Sources;