import "./registration.css"
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

async function registrationFormValid(e) {
  e.preventDefault();

  const registrationForm = new FormData();
  registrationForm.append('username', document.querySelector(".registrationUsername").value);
  registrationForm.append('password', document.querySelector(".registrationUserpass").value);
  registrationForm.append('email', document.querySelector(".registrationUsermail").value);
  registrationForm.append('telegram', document.querySelector(".registrationUsertelegram").value);


  await axios.post(process.env.REACT_APP_API_URL + '/auth/register/', registrationForm)
    .then(function (response) {
      if (response.status == "200") {
        document.querySelector(".registrationFormContainer").classList.add('dn');
        document.querySelector(".confirmMessageCont").classList.remove('dn');
      }


    })
    .catch(function (error) {
      const lge = document.querySelector(".errorMessage");
      lge.classList.remove("dn");

    });
}


const Registration = () => {
  const { t } = useTranslation();
  return (
    <div className="registrationCont">

      <div className="registrationFormContainer" id="registration">
        <h2>{t("rTitle")}</h2>
        <form className='registrationForm' method="post" action="#" encType='multipart/form-data' onSubmit={registrationFormValid}>
          <div className="registrationInputs">
            <input required placeholder={t("rLoginPh")} type="text" name="username" className="registrationUsername" />
            <input required placeholder={t("rTelegramPh")} type="text" name="usertelegram" className="registrationUsertelegram" />
            <input required pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" autoComplete="email" placeholder={t("rMailPh")} type="email" name="useremail" className="registrationUsermail" />

            <input required placeholder={t("rPassPh")} type="password" name="userpass" />
            <input required placeholder={t("rConfPassPh")} type="password" name="userpassconfirm" className="registrationUserpass" />
          </div>
          <div className="errorMessage dn">{t("Errormessage")}</div>

          <button>
          {t("Toregistration")}
          </button>
        </form>
        <Link to={"/login"}>{t("Tologin")}</Link>
      </div>
      <div className="confirmMessageCont dn">
        <div className="confirmMessage">
          <p>
          {t("rConfMaleMessage")}
          </p>
        </div>


      </div>
    </div >

  )
}


export default Registration;
