import { useTranslation } from 'react-i18next'
import "./source-nav.css"




const SourcesNav = ({ data }) => {

    const { t } = useTranslation();

    const navItems = data.map(item => {
        return (
            <li>
                <a href={'#' + item.title}><img src={item.navImg} alt="" /></a>
            </li>
        )
    })

    return (
        <div className="navAgency">
            <div className="chooseSourceCont">
                <h3 className="chooseSource">
                {t("agChoisesource")}
                </h3>
            </div>

            <div className="sourcesNavCont">
                <nav className="sourcesNav">
                    <ul>
                        {navItems}
                    </ul>
                </nav>
            </div>

            <div className="arrow">
                <a href={'#' + data[0].title}><img src="img/agency/arrow.png" alt="" /></a>

            </div>
        </div>


    )
}

export default SourcesNav;