import Header from '../header/header'
import Agency from '../agency/agency'
import Consumables from '../consumables/consumables'
import Spends from '../spends/spends'
import Card from '../card/card'
import Background from '../background'
import Login from '../login/login'
import Registration from '../registration/registration'
import Profile from '../profile/profile'
import Footer from '../footer/footer'
import Noauth from '../noauth/noauth'
import TaC from '../tac/tac'
import Pp from '../pp/pp'

import './app.css';

import { Route, Routes, Navigate } from 'react-router-dom'
import {useTranslation} from 'react-i18next'

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : "";
}

 console.log(getCookie("csclang"))



function App() {
  
  
    const {t, i18n} = useTranslation();
    const changeLanguage = (language) => {
  
      i18n.changeLanguage(language);
    }
  
  
  
    
  
    
    function langToggler (language) {
      switch (language) {
        case "en":
          changeLanguage('ru');
          document.cookie = 'csclang=ru';
          break;
        // case "ua":
        //   changeLanguage('ru');
        //   document.cookie = 'csclang=ru';
        //   break;
        case "ru":
          changeLanguage('en')
          document.cookie = 'csclang=en'
          break;
        default:
          changeLanguage('ru');
          document.cookie = 'csclang=ru'
      }
    }
  

  return(

    <div  className='app'>
      <Header />
    
      {/* <div className='changeLanguage' onClick={() => langToggler(document.querySelector(".changeLanguage").textContent)}>
        {t("lang") }
  
        </div> */}
      
            <Routes>
              <Route index element={<Navigate to="/agency" />} />
              <Route path="/agency" element={<Agency/>} />
              <Route path="/consumables" element={<Consumables/>} />
              <Route path="/spend" element={getCookie("csctoken") !== "" || null || undefined ? <Spends/> : <Navigate replace to="/noauth" />} />
              <Route path="/card" element={<Card/>} />
              <Route path="/profile" element={getCookie("csctoken") !== ""  ? <Profile/> : <Navigate replace to="/login" />}/>
              <Route path="/login" element={getCookie("csctoken") !== "" ? <Navigate replace to="/profile" /> : <Login/>} />
              <Route path="/registration" element={getCookie("csctoken") !== ""  ? <Navigate replace to="/profile" /> : <Registration/>} />
              <Route path="/noauth" element={getCookie("csctoken") !== ""  ? <Navigate replace to="/spend" /> :  <Noauth/>} />
              <Route path="/termsandconditions" element={<TaC/>} />
              <Route path="/privacypolicy" element={<Pp/>} />
            </Routes>
        <Footer/>
      <Background />
    </div>
  )
}



export default App;

