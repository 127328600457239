import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'



// function getCookie(name) {
//     let matches = document.cookie.match(new RegExp(
//       "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//     ));
//     return matches ? decodeURIComponent(matches[1]) : "";
//   }

  console.log(localStorage.getItem("i18nextLng"))
  
   if (localStorage.getItem("i18nextLng") == null) {
    localStorage.setItem("i18nextLng", "en");
   }

  

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
fallbackLng: localStorage.getItem("i18nextLng"),
debug: true,
detection: {
    order: [ 'queryString', 'cookie'],
    cache:[ 'cookie']
},
interpolation: {
    escapeValue: false
}

})

export default i18n