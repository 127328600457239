import React from 'react';
import { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

import './background.css'

const Background = () => {
    const particlesInit = useCallback(async engine => {

        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
    }, []);


    return (
        <div className='tsparticlesCont'>

            <Particles

                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{

                    // zIndex: {
                    //     value: -1000001
                    // },
                    background: {

                        // color: {
                        //     value: "#fff",
                        // },
                    },
                    fpsLimit: 90,
                    interactivity: {
                        events: {
                            onHover: {

                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 10,
                            },
                            repulse: {
                                distance: 150,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#000",
                        },
                        // ЛІНІЇ
                        // links: {
                        //     color: "#000",
                        //     distance: 100,
                        //     enable: true,
                        //     opacity: 0.5,
                        //     width: 1,
                        // },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 4,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 600,
                            },
                            value: 80,
                        },

                        opacity: {
                            value: { min: 0.2, max: 1 },
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: false,
                }}

            />
        </div>
    );
};

export default Background;