import { useTranslation } from 'react-i18next'
import './profile.css'


const Profile = () => {

    const { t } = useTranslation();

    function logout () {
        document.cookie = "csctoken=";
        window.location.reload();
    }


    return (
        <div className='profileCont'>
            <h2>{t("proWelcome")}{localStorage.getItem("csclogin")}</h2>

            <a href="/login" className="logout">
                <p onClick={logout}>{t("proLogoutBtn")}</p>
            </a>
        </div>
    )
}

export default Profile