import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/app';
import { BrowserRouter } from 'react-router-dom';

import './i18next'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
    
    <App />
    
    </BrowserRouter>

);
