import React, { Component } from 'react';
import './consumables.css';
import axios from 'axios';


class Consumables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consumables: [],
        };

        this.conses = this.conses.bind(this);
    }

    async componentDidMount() {
        await this.conses();
    }

    async conses() {
        try {
            const response = await axios(process.env.REACT_APP_API_URL + '/consumables/');
            this.setState({ consumables: response.data ?? [] });
            const loader = document.querySelector(".loader");
            loader.classList.add('dn');
            const cont = document.querySelector(".consumablesList");
            cont.classList.remove('dn');
        } catch (error) {
            this.setState({ consumables: [] });
        }
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : "";
    }


    cardToggler = (event) => {
        const front = event.target.parentNode.querySelector('.cardFront');
        const back = event.target.parentNode.querySelector('.cardBack');
        front.classList.toggle("cardFrontA");
        back.classList.toggle("cardBackA");
    };

    render() {
        const { consumables } = this.state;



        const consumablesList = consumables.map(item => (
            <div className="consumableItem" key={item.title}>
                <div className="consumableLogo ">
                    <a href={'#' + item.title + 'cons'} >
                        <img src={item.logo} alt="" className='consumableImg' />
                        <img src={item.hover} alt="" className='consumableHover' />
                        {/* <img src="img/fbt.png" alt="" className='consumableImg' />
                        <img src="img/fbth.png" alt="" className='consumableHover' /> */}
                    </a>
                </div>
                <div className="consumableCards" id={item.title + 'cons'}>
                    {item.cards.map((cardItem, index) => (
                        <div className="contCardT">
                            <div className="consCardWrap" key={index}>
                                <img src={cardItem.front} className="cardFront" alt="" onClick={this.cardToggler} />
                                <img src={cardItem.back} className="cardBack" alt="" onClick={this.cardToggler} />
                            </div>
                            <a href="https://t.me/CSCNavigation_Bot">
                                <img className="atb" src="img/agency/telegramBlack.png" alt="" />
                                <img className="atw" src="img/auth/telegramWhite.png" alt="" />
                            </a>

                        </div>
                    ))}
                </div>
            </div>
        ));

        return (
            <div className="consCont">
                <div className="loader">
                    <img src="img/giphy.gif" alt="" />
                </div>
                <div className="consumablesList dn" id={'consumables'}>
                    {consumablesList}
                </div>

            </div>

        );
    }
}

export default Consumables;